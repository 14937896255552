import React, { useEffect, useState } from "react";
import contact from "./../../json/contact-us.json";
import * as Icons from "./../../icons";
import { callGetContactUs } from "../../APIs/ContactUs.api";
import { token } from "../../utils/helper";
import "./ContactUsForm.scss";

const ContactUsFormComponent = () => {
  const [csrfToken, setCsrfToken] = useState<string | undefined>();

  useEffect(() => {
    const tempToken = token();
    setCsrfToken(tempToken);
  }, []);

  const validNameRegex = RegExp(/^[a-z0-9 ,\-.'\&`’]+$/i);
  const validMobileNoRegex = RegExp(/^[0-9]+$/);
  const validRoleRegex = RegExp(/^[a-z0-9 ,\-.'\&`’]+$/i);
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const [apiFailedError, setApiFailedError] = useState("");
  const [loading, setLoading] = useState({
    message: "",
    flag: false,
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phone: "",
    message: "",
    role: "",
    csrfToken: "",
  });

  const [state, setState] = useState({
    firstName: null,
    lastName: null,
    email: null,
    company: null,
    phone: null,
    role: null,
    message: null,
    errors: {
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      phone: "",
      message: "",
      role: "",
    },
  });

  const [errors, setErrors] = useState(state.errors);

  const onFieldChange = (event: any) => {
    let keys = Object.keys(event);
    const keyName: any = keys;
    const filter = { ...formData, ...event };
    setFormData(filter);
    validateFormValue(...keyName, event[keyName]);
  };

  const validateFormValue = (name: string, value: any) => {
    let errors = state.errors;
    switch (name) {
      case "firstName":
        errors.firstName =
          value.length === 0
            ? "First name is required"
            : value.length <= 1 || value.length > 100
            ? "First name should be between 2 to 100 alphanumeric characters and Special characters (- . _ , ' &), space  are allowed."
            : validNameRegex.test(value)
            ? ""
            : "First name should be between 2 to 100 alphanumeric characters and Special characters (- . _ , ' &), space  are allowed.";
        break;

      case "lastName":
        errors.lastName =
          value.length === 0
            ? "Last name is required"
            : value.length <= 1 || value.length > 100
            ? "Last name should be between 2 to 100 alphanumeric characters and Special characters (- . _ , ' &), space  are allowed."
            : validNameRegex.test(value)
            ? ""
            : "Last name should be between 2 to 100 alphanumeric characters and Special characters (- . _ , ' &), space  are allowed.";
        break;
      case "email":
        errors.email =
          value.length === 0
            ? "Email is required"
            : validEmailRegex.test(value)
            ? ""
            : "Email is not valid!";
        break;
      case "company":
        errors.company =
          value.length === 0
            ? "Company name is required"
            : value.length <= 1 || value.length > 100
            ? "Company name should be between 2 to 100 alphanumeric characters and Special characters (- . _ , ' &), space  are allowed."
            : validNameRegex.test(value)
            ? ""
            : "Company name should be between 2 to 100 alphanumeric characters and Special characters (- . _ , ' &), space  are allowed.";
        break;
      case "phone":
        errors.phone =
          value.length === 0
            ? "Phone Number is required"
            : validMobileNoRegex.test(value)
            ? value.length < 7 || value.length > 14
              ? "Phone number should be in between 7 to 14 digits."
              : ""
            : "Please enter valid phone number";
        break;
      case "role":
        errors.role =
          value.length === 0
            ? ""
            : value.length <= 1 || value.length > 100
            ? "Role should be between 2 to 100 alphanumeric characters and Special characters (- . _ , ' &), space  are allowed."
            : validRoleRegex.test(value)
            ? ""
            : "Role should be between 2 to 100 alphanumeric characters and Special characters (- . _ , ' &), space  are allowed.";
        break;

      case "message":
        errors.message =
          value.length === 0
            ? ""
            : value.length > 1000
            ? "Message shouldn't be more than 1000 alphanumeric characters."
            : "";
        break;

      default:
        break;
    }
    let stateNewValue = state;
    stateNewValue.errors = errors;
    stateNewValue[name] = value;
    setState(stateNewValue);
    setErrors({ ...errors });
  };

  const validateForm = (errors: any) => {
    let valid = true;
    Object.values(errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    let requiredObjectKey = [
      "firstName",
      "lastName",
      "email",
      "company",
      "phone",
      "role",
      "message",
    ];
    requiredObjectKey.forEach((keyName: any) => {
      if (!state[keyName]) {
        validateFormValue(keyName, "");
      }
    });
    if (validateForm(state.errors)) {
      setLoading({ message: "", flag: true });
      const params = {
        name: formData["firstName"] + " " + formData["lastName"],
        email: formData["email"],
        companyName: formData["company"],
        contactNumber: formData["phone"],
        token: csrfToken,
      };

      if (formData["role"]) {
        params["role"] = formData["role"];
      }
      if (formData["message"]) {
        params["message"] = formData["message"];
      }
      callGetContactUs(JSON.stringify(params))
        .then((res) => {
          resetFormValue();
          setFormData({
            firstName: "",
            lastName: "",
            company: "",
            email: "",
            phone: "",
            message: "",
            role: "",
          });

          setLoading({
            message: "Your request has been sent successfully",
            flag: false,
          });

          setTimeout(() => {
            setLoading({
              message: "",
              flag: false,
            });
          }, 5000);
        })
        .catch((error) => {
          // setLoading({ message: error.message, flag: false })
          setApiFailedError(error.status + " " + error.message);
          setLoading({ message: "", flag: false });
          setTimeout(() => {
            setApiFailedError("");
          }, 8000);
        });
    }
  };

  const resetFormValue = () => {
    setState({
      firstName: null,
      lastName: null,
      email: null,
      company: null,
      phone: null,
      role: null,
      message: null,
      errors: {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",
        message: "",
        role: "",
      },
    });
    setErrors(state.errors);
  };

  return (
    <div className="contactUsFormContainer">
      {loading.flag && (
        <div className="loader">
          <img src="./../static/loader.gif" width="70" />{" "}
        </div>
      )}

      {loading.message && (
        <div className="message">
          <Icons.StickIcon />
          <h3>{loading.message}</h3>
        </div>
      )}

      {apiFailedError && (
        <div className="api-failed-error">
          <p>
            <Icons.CrossIcon /> {apiFailedError}
          </p>
        </div>
      )}

      {!loading.message && (
        <form onSubmit={onSubmitForm} noValidate>
          <div className="contact-us-form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-control">
                  <label>{contact.firstName} *</label>
                  <input
                    type="text"
                    placeholder={contact.firstNamePlaceholder}
                    value={formData.firstName || ""}
                    onChange={(e) =>
                      onFieldChange({ firstName: e.target.value })
                    }
                    name="firstName"
                    required
                  />
                  {errors && errors.firstName && errors.firstName.length > 0 ? (
                    <span className="validation-msg">{errors.firstName}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-control">
                  <label>{contact.lastName} *</label>
                  <input
                    type="text"
                    value={formData.lastName || ""}
                    placeholder={contact.lastNamePlaceholder}
                    onChange={(e) =>
                      onFieldChange({ lastName: e.target.value })
                    }
                    name="lastName"
                    required
                  />
                  {errors && errors.lastName && errors.lastName.length > 0 ? (
                    <span className="validation-msg">{errors.lastName}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-control">
                  <label>{contact.email} *</label>
                  <input
                    type="email"
                    value={formData.email || ""}
                    placeholder={contact.emailPlaceholder}
                    onChange={(e) => onFieldChange({ email: e.target.value })}
                    required
                    name="email"
                  />
                  {errors && errors.email && errors.email.length > 0 ? (
                    <span className="validation-msg">{errors.email}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-control">
                  <label>{contact.company} *</label>
                  <input
                    type="text"
                    value={formData.company || ""}
                    placeholder={contact.compnanyPlaceholder}
                    onChange={(e) => onFieldChange({ company: e.target.value })}
                    required
                    name="company"
                  />
                  {errors && errors.company && errors.company.length > 0 ? (
                    <span className="validation-msg">{errors.company}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-control">
                  <label>{contact.Role}</label>
                  <input
                    type="text"
                    value={formData.role || ""}
                    placeholder={contact.RolePlaceholder}
                    onChange={(e) => onFieldChange({ role: e.target.value })}
                    required
                    name="role"
                  />
                  {errors && errors.role && errors.role.length > 0 ? (
                    <span className="validation-msg">{errors.role}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-control">
                  <label>{contact.phone} *</label>
                  <input
                    type="text"
                    value={formData.phone || ""}
                    placeholder={contact.phonePlaceholder}
                    onChange={(e) => onFieldChange({ phone: e.target.value })}
                    required
                    name="phone"
                    className="phoneNumber"
                  />
                  {errors && errors.phone && errors.phone.length > 0 ? (
                    <span className="validation-msg">{errors.phone}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-control">
                  <label>{contact.Message}</label>
                  <textarea
                    type="text"
                    placeholder={contact.MessagePlaceholder}
                    value={formData.message || ""}
                    onChange={(e) => onFieldChange({ message: e.target.value })}
                    rows="4"
                    name="message"
                  />
                  {errors && errors.message && errors.message.length > 0 ? (
                    <span className="validation-msg">{errors.message}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div>
                <input type="hidden" name="token" value={csrfToken} />
              </div>

              <div className="col-md-12">
                <button type="submit" className="primary-green-btn">
                  {contact.contactUs}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactUsFormComponent;
