import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import * as Icons from "../icons";
import Layout from "../components/Layout/index";
import ContactUsFormComponent from "../components/ContactUsForm";
import contact from "./../json/contact-us.json";
import favIcon from '../images/FosterTalent_fav_icon.png';
import "./contact-us.scss";

const ContactUs = () => {
  const [contactUsData, setContactUsData] = useState();

  const data = useStaticQuery(graphql`
    {
      allContentfulContactUs {
        edges {
          node {
            title
            description
            link
            buttonText
          }
        }
      }
    }
  `);

  useEffect(() => {
    setContactUsData(data["allContentfulContactUs"]["edges"]);
  }, [data]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Foster Talent</title>
        <link rel="icon" type="image/x-icon" href={favIcon} />
      </Helmet>
      <Layout>
        {contactUsData && (
          <div className="contactUsContainer">
            <div className="contact-us">
              <div className="contact-us__left">
                <h3>{contact.header} <br />{contact.connectWithUs} </h3>
                <div className="contact-us__left--box">
                  <h2>{contactUsData[0]["node"]["title"]}</h2>
                  <p>{contactUsData[0]["node"]["description"]}</p>
                  {/* <button>{contactUsData[0]["node"]["buttonText"]}</button> */}
                </div>

                <div className="contact-us__left--box">
                  <h2>{contactUsData[1]["node"]["title"]}</h2>
                  <p>{contactUsData[1]["node"]["description"]}</p>
                  <a href="mailto:vipin.kr@smartsensesolutions.com" target="blank" className="emailIcon">
                    <Icons.EmailIcon />vipin.kr@smartsensesolutions.com
                  </a>
                  <a>
                    <Icons.CallIcon />+91 99407 63752
                  </a>
                </div>
              </div>

              <div className="contact-us__right">
                <div className="contact-us__right--form">
                  <ContactUsFormComponent />
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>

  );
};

export default ContactUs;
