import axios from "axios";
import { ACCESS_TOKEN_KEY } from "./constants";
// import { getAlert } from '../hooks';
import {
  existKeyInStore,
  getActualResponseFromAxiosRequest,
  getAuthToken,
  redirectToLogin,
  removeFromStore,
  removeSelectedEnterpriseId,
  returnParsedJson,
} from "./helper";

const defaultHeaders = {
  "Content-Type": "application/json; charset=UTF-8",
};

let loaderCount = 0;

const axiosInstance = axios.create({
  // @ts-ignore
  baseURL: import.meta.env
    ? import.meta.env.VITE_API_BASE
    : process.env.VITE_API_BASE,
  headers: {
    ...defaultHeaders,
  },
});

axiosInstance.interceptors.request.use((config: any) => {
  if (existKeyInStore(ACCESS_TOKEN_KEY)) {
    const tokenObject = getAuthToken();
    const token = tokenObject.t || "";
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      removeFromStore(ACCESS_TOKEN_KEY);
      redirectToLogin();
      removeSelectedEnterpriseId();
    }
    const parsedJson = returnParsedJson(
      getActualResponseFromAxiosRequest(error)
    );
    if (!parsedJson) {
      return Promise.reject(error);
    }
    return Promise.reject(JSON.parse(getActualResponseFromAxiosRequest(error)));
  }
);

const get = async (
  endpoint: string,
  headers: any = {},
  showLoader: boolean = true,
  showAlert: boolean = false
) => {
  if (showLoader) {
    showLoading();
  }

  return axiosInstance
    .get(endpoint, {
      headers: {
        ...headers,
      },
    })
    .then((resp: any) => {
      if (
        showAlert &&
        resp &&
        resp.message &&
        typeof resp.message === "string" &&
        resp.show === true
      ) {
        // getAlert('success', resp.message);
      }
      return resp;
    })
    .catch((error) => {
      if (error && error.message && error.status !== 404) {
        // getAlert('error', error.message);
      }
    })
    .finally(() => {
      if (showLoader) {
        hideLoading();
      }
    });
};

const post = async (
  endpoint: string,
  data: any = {},
  customHeaders: any = {},
  showLoader: boolean = true,
  showAlert: boolean = true,
  baseURL?: string
) => {
  if (showLoader) {
    showLoading();
  }

  return axiosInstance
    .post(endpoint, data, {
      headers: {
        ...customHeaders,
      },
      baseURL,
    })
    .then((resp: any) => {
      if (
        showAlert &&
        resp &&
        resp.message &&
        typeof resp.message === "string" &&
        resp.show === true
      ) {
        // getAlert('success', resp.message);
      }
      return resp;
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      if (showLoader) {
        hideLoading();
      }
    });
};

const put = async (
  endpoint: string,
  data: any = {},
  customHeaders: any = {},
  showLoader: boolean = true,
  showAlert: boolean = true
) => {
  if (showLoader) {
    showLoading();
  }

  return axiosInstance
    .put(endpoint, data, {
      headers: {
        ...customHeaders,
      },
    })
    .then((resp: any) => {
      if (
        showAlert &&
        resp &&
        resp.message &&
        typeof resp.message === "string" &&
        resp.show === true
      ) {
        //  getAlert('success', resp.message);
      }
      return resp;
    })
    .catch((error) => {
      if (error && error.message) {
        //  getAlert('error', error.message);
      }
    })
    .finally(() => {
      if (showLoader) {
        hideLoading();
      }
    });
};

const deleteAPI = async (
  endpoint: string,
  data?: any,
  customHeaders: any = {},
  showLoader: boolean = true,
  showAlert: boolean = true
) => {
  if (showLoader) {
    showLoading();
  }

  return axiosInstance
    .delete(endpoint, {
      headers: { ...customHeaders },
      data: data,
    })
    .then((resp: any) => {
      if (
        showAlert &&
        resp &&
        resp.message &&
        typeof resp.message === "string" &&
        resp.show === true
      ) {
        //  getAlert('success', resp.message);
      }
      return resp;
    })
    .catch((error) => {
      if (error && error.message) {
        //  getAlert('error', error.message);
      }
    })
    .finally(() => {
      if (showLoader) {
        hideLoading();
      }
    });
};

const getBlob = async (
  endpoint: string,
  headers: any = {},
  showLoader: boolean = true,
  showAlert: boolean = false
) => {
  if (showLoader) {
    showLoading();
  }

  return axiosInstance
    .get(endpoint, {
      headers: {
        ...headers,
      },
      responseType: "blob",
    })
    .then((resp: any) => {
      if (
        showAlert &&
        resp &&
        resp.message &&
        typeof resp.message === "string" &&
        resp.show === true
      ) {
        //  getAlert('success', resp.message);
      }
      return resp;
    })
    .catch((error) => {
      if (error && error.message) {
        //  getAlert('error', error.message);
      }
    })
    .finally(() => {
      if (showLoader) {
        hideLoading();
      }
    });
};

const getAxiosInstance = () => {
  return axiosInstance;
};

//helper function to show/hide the loader
const showLoading = () => {
  const linearLoader = document.getElementsByClassName("linearLoader");
  if (linearLoader && linearLoader.length > 0) {
    loaderCount += 1;
    linearLoader[0].classList.remove("d-none");
  }
};

const hideLoading = () => {
  const linearLoader = document.getElementsByClassName("linearLoader");
  loaderCount -= 1;
  if (linearLoader && linearLoader.length > 0 && loaderCount <= 0) {
    linearLoader[0].classList.add("d-none");
  }
};

export { get, post, put, deleteAPI, getBlob, getAxiosInstance };
