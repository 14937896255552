// @ts-nocheck
/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AxiosError } from "axios";
import { AuthToken } from "../types/common";
import { ACCESS_TOKEN_KEY, DEFAULT_CHARSET, REDIRECT_LOGIN } from "./constants";

/**
 * This is just demo function which simulates the login api call
 */
export const logIn = (username: string, password: string) => {
  return new Promise((resolve: any, reject) => {
    setTimeout(() => {
      localStorage.setItem(
        "user",
        JSON.stringify({
          username,
          password,
        })
      );
      resolve();
    }, 5000);
  });
};
/**
 *  This is just demo function with simulates the logout api call
 */
export const logOut = () => {
  return new Promise((resolve: any, reject) => {
    setTimeout(() => {
      localStorage.removeItem("user");
      sessionStorage.removeItem("studentDetail");
      resolve();
    }, 5000);
  });
};

export const getFromLocalstorage = (key: string) => {
  try {
    // @ts-ignore
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {}
};

export const existKeyInStore = (key: string) => {
  const item = getFromLocalstorage(key);
  if (item) {
    return true;
  } else {
    return false;
  }
};
/**
 *
 * @param data Data got after http request from axios
 * @returns
 * @description Used in HTTP.ts to get data from request in axios
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getActualResponseFromAxiosRequest = (data: AxiosError) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return data.request.response;
};

export const getFromStore = (key: string): any => {
  const item = localStorage.getItem(key);
  try {
    // @ts-ignore
    return JSON.parse(item);
  } catch (e) {
    return item;
  }
};
export const getAuthToken = (): AuthToken => {
  const token: AuthToken = {
    t: "",
  };
  try {
    const tokenFromStorage = getFromStore(ACCESS_TOKEN_KEY);
    token.t = tokenFromStorage?.t;
    return token;
  } catch (e) {
    return token;
  }
};

export const redirectToLogin = () => {
  location.replace(`${REDIRECT_LOGIN}`);
};

export const removeFromStore = (key: string) => {
  const item = getFromLocalstorage(key);
  if (item) {
    localStorage.removeItem(key);
    return true;
  } else {
    return false;
  }
};

export const removeSelectedEnterpriseId = () => {
  return localStorage.removeItem("see");
};

export function returnParsedJson(jsonObject: any) {
  try {
    const parsedJson = JSON.parse(jsonObject);
    return parsedJson;
  } catch (e) {
    return false;
  }
}

export const setToStore = (key: string, obj = {}) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

export const getFromSessionStorage = (key: string) => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (e) {}
};

export const getRandomValues = (
  length: number,
  characterString: string = DEFAULT_CHARSET
): string => {
  let result = "";
  const crypto = window.crypto;
  const randomValueArray = new Uint32Array(length);
  crypto.getRandomValues(randomValueArray);
  for (let i = 0; i < length; i++) {
    result += characterString[randomValueArray[i] % characterString.length];
  }
  return result;
};

export const rand = function () {
  const lengthNumber = 11;
  const substrNumber = 2;
  return getRandomValues(lengthNumber).substring(substrNumber); // remove `0.`
};

export const token = function () {
  return rand() + rand(); // to make it longer
};
